/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { PoseGroup } from 'react-pose';

import { isBrowserIE11 } from 'utils/styles';

import Image from 'components/common/ImageGatsbyCraft';
import { AbsoluteCenterStyled } from './ProductSceneryStyled';
import {
  BottleSwitchPosed,
  BottlePosed,
  sceneryLayers,
} from './ProductSceneryPosed';

export default function ProductScenery({
  sceneryImages,
  currentSection,
  isStageExtended,
  isMobile,
  slug,
}) {
  return (
    <>
      {sceneryLayers
        .map(
          (LayerPosed, index) =>
            sceneryImages.layers[index] && (
              <AbsoluteCenterStyled
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                animationDelay={`${(index + 1) * -3000}ms`}
                animationPlayState={isStageExtended ? 'running' : 'paused'}
              >
                <LayerPosed
                  pose={currentSection.key}
                  style={{ willChange: 'transform' }}
                >
                  <Image
                    fadeIn={false}
                    fluid={sceneryImages.layers[index]}
                    style={{ width: '50vw' }}
                    objectFit="contain"
                    altText={sceneryImages?.layers[index]?.altText}
                  />
                </LayerPosed>
              </AbsoluteCenterStyled>
            ),
        )
        .reverse()}
      {sceneryImages.bottle && (
        <AbsoluteCenterStyled
          animationPlayState={isStageExtended ? 'running' : 'paused'}
        >
          <BottlePosed
            pose={
              isMobile ? `${currentSection.key}_mobile` : currentSection.key
            }
            style={{ willChange: 'transform' }}
          >
            {isBrowserIE11() ? (
              <Image
                backgroundColor="transparent"
                fadeIn={false}
                fluid={sceneryImages.bottle}
                style={{ width: '12vw', maxWidth: 170 }}
                objectFit="contain"
                altText={sceneryImages?.bottle?.altText}
              />
            ) : (
              <PoseGroup>
                <BottleSwitchPosed
                  key={slug}
                  style={{
                    willChange: 'transform',
                    backfaceVisibility: 'none',
                  }}
                >
                  <Image
                    backgroundColor="transparent"
                    fadeIn={false}
                    fluid={sceneryImages.bottle}
                    style={{ width: '12vw', maxWidth: 170 }}
                    objectFit="contain"
                    altText={sceneryImages?.bottle?.altText}
                  />
                </BottleSwitchPosed>
              </PoseGroup>
            )}
          </BottlePosed>
        </AbsoluteCenterStyled>
      )}
    </>
  );
}
