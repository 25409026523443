import styled from '@emotion/styled';

import { toPx } from 'utils/styles';
import media from 'utils/mediaqueries';

import Link from 'components/common/Link';
import Image from 'components/common/ImageGatsbyCraft';

export const PRODUCT_SWITCHER_BREAKPOINT = 'tabletFluid';

/**
 * Wrapper around flickety slider.
 */
export const ProductSwitcherStyled = styled('div')({
  position: 'relative',
  top: 0,
  width: '100%',
  backfaceVisibility: 'hidden',
  margin: '0 auto',
  pointerEvents: 'all',
  transition: 'opacity .25s, transform 500ms ease-in-out',

  // Radial background shadow 2 (see below)
  '&::after': {
    [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
      content: '""',
      position: 'absolute',
      width: 100,
      top: -70,
      height: '100%',
      zIndex: -1,
      pointerEvents: 'none',
      background: 'radial-gradient(rgba(0,0,0,1) 0%,rgba(0,0,0,0) 80%)',
      transform: 'rotate(90deg) scaleY(2) translate(-100%, 0)',
    },
  },

  [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
    transform: 'translate(0,-40%)',
    width: '100%',
    top: '50%',
    left: '12%',
  },

  [media('laptop')]: {
    transform: 'translate(0,-30%)',
    left: '8%',
  },

  [media(1200)]: {
    transform: 'translate(0,-20%)',
    left: '5%',
  },

  [media('desktop')]: {
    transform: 'translate(0,-20%)',
    left: '3%',
  },

  '.flickity-enabled': {
    position: 'relative',

    // Linear gradient shadow.
    '&::before': {
      position: 'absolute',
      content: '""',
      display: 'block',
      backgroundColor: 'rgba(0, 0, 0, 1)',
      width: '100%',
      height: '85%',
      top: 0,
      left: 0,

      [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
        width: '40%',
        height: '100%',
        left: -2,
        zIndex: 1,
        pointerEvents: 'none',
        background:
          'linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%)',
      },
    },

    // Radial background shadow.
    '&::after': {
      [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
        content: '""',
        position: 'absolute',
        width: 240,
        top: '-22%',
        height: '100%',
        zIndex: -1,
        pointerEvents: 'none',
        background:
          'radial-gradient(circle, rgba(0,0,0,1) 20%,rgba(0,0,0,0) 60%)',
        transform: 'scaleY(2) translate(-50%, 0)',
      },
    },
  },

  '.flickity-viewport': {
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
  },

  '.flickity-slider': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    pointerEvents: 'all',
  },

  '.flickity-slider .is-selected': {
    pointerEvents: 'none',
  },

  '.flickity-enabled:focus': {
    outline: 0,
  },
});

/**
 * Slider controls.
 */
export const SliderControlsStyled = styled('div')(
  {
    display: 'none',
    position: 'relative',
    width: '50%',
    left: '20%',
    alignItems: 'center',
    justifyContent: 'space-between',

    [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
      display: 'flex',
    },
  },
  (props) => ({
    marginTop: props.theme.space,
  }),
);

export const ArrowStyled = styled('button')(
  {
    flex: 'none',
    width: 40,
    height: 40,
    border: 0,
    zIndex: 1,
    backgroundColor: 'transparent',
    color: 'rgba(255,255,255,.5)',
    cursor: 'pointer',
    display: 'none',
    transition: 'transform 0.2s ease-in-out',

    [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
      display: 'block',
    },
  },
  (props) => ({
    '&:hover, &:focus': {
      color: props.theme.colorPrimary,
      WebkitTapHighlightColor: 'transparent',
      outline: 0,
    },
  }),
);

export const PrevStyled = styled(ArrowStyled)({
  '&:hover': {
    transform: 'translate(-20%, 0)',
  },
});

export const NextStyled = styled(ArrowStyled)({
  '&:hover': {
    transform: 'translate(20%, 0)',
  },
});

export const IndicatorStyled = styled('div')({
  position: 'relative',
});

/**
 * Wrapper around flickety slide item.
 */
export const ProductSwitcherItemStyled = styled('div')({
  position: 'relative',
  height: 460,
  paddingBottom: 20,

  [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
    top: 0,
    width: 100,
    height: 350,
    paddingBottom: 20,
  },
});

/**
 * Product preview
 */
export const ProductPreviewStyled = styled(Link)(
  {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',

    opacity: '0.7',
    transformOrigin: 'bottom center',
    transform: 'scale(.4) translate(0, -50%)',
    transition: 'opacity .15s, transform .35s ease-in-out',

    [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
      opacity: '0.7',
      transformOrigin: 'bottom center',
      transform: 'scale(.8) translate(0, 0)',
      transition: 'opacity .15s, transform .45s ease-in-out',
    },

    '&:focus, &:hover': {
      WebkitTapHighlightColor: 'transparent',
      opacity: '1',
    },

    '@media(hover: hover)': {
      '&:focus, &:hover': {
        [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
          transform: 'scale(1)',
        },
      },
    },

    '.is-selected &': {
      transform: 'scale(1) translate(0,0)',
      opacity: '1',
    },

    '.is-selected & [data-product-title]': {
      opacity: 0,
    },
  },
  (props) =>
    props.shine && {
      '@media(hover: hover)': {
        '&:focus, &:hover': {
          [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
            filter: `drop-shadow(0 0 10px rgba(${props.shine?.r},${props.shine?.g},${props.shine?.b}, .5))`,
          },
        },
      },
    },
);

export const ProductPreviewTitleStyled = styled('div')(
  {
    textTransform: 'uppercase',
    wordBreak: 'break-word',
    textAlign: 'center',
    transition: 'opacity 150ms ease-in-out',
  },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
    marginBottom: `${toPx(props.theme.space * 1.5)}`,
  }),
);

export const ProductPreviewImageStyled = styled(Image)({
  flex: 'none',

  '&.gatsby-image-wrapper': {
    height: '380px !important',
    width: '112px !important',

    [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
      height: '220px !important',
      width: '100% !important',
    },
  },
});
