import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';
import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';
import FlickityComponent from 'components/common/FlickityComponent';
import { navigate } from 'gatsby';

import { parseAsContent } from 'utils/parser';

import {
  ProductSwitcherStyled,
  ProductSwitcherItemStyled,
  ProductPreviewStyled,
  ProductPreviewTitleStyled,
  ProductPreviewImageStyled,
  SliderControlsStyled,
  PrevStyled,
  NextStyled,
  IndicatorStyled,
} from './ProductSwitcherStyled.js';

export default function ProductSwitcher({
  products,
  currentSlug,
  currentSection,
  navigateOnSelect,
}) {
  const [initialIndex] = useState(() =>
    products.findIndex(({ slug }) => slug === currentSlug),
  );

  if (typeof window === 'undefined') {
    return null;
  }

  const currentIndex = products.findIndex(({ slug }) => slug === currentSlug);
  const sliderIndicatorAll = products.length;
  const sliderIndicatorCurrent = currentIndex + 1;

  const flickityRef = useRef(null);
  const [flickityReady, setFlickityReady] = useState(false);

  useEffect(() => {
    function handleReady() {
      setFlickityReady(true);
    }

    if (flickityRef.current) {
      flickityRef.current.on('ready', handleReady);
    }

    return () => {
      flickityRef.current.off('ready', handleReady);
    };
  }, [flickityRef]);

  useEffect(() => {
    if (flickityReady && flickityRef.current) {
      flickityRef.current.select(currentIndex, true);
    }
  });

  useEffect(() => {
    function handleSelect(index) {
      if (index !== currentIndex) {
        if (navigateOnSelect || window.innerWidth < 737) {
          navigate(products[index].fullUri);
        }
      }
    }

    if (flickityRef.current) {
      flickityRef.current.on('select', handleSelect);
    }

    // cleanup
    return () => {
      flickityRef.current.off('select', handleSelect);
    };
  }, [flickityRef, currentIndex, navigateOnSelect]);

  // Callback: slide next
  const sliderNext = useCallback(() => {
    if (flickityRef.current) {
      flickityRef.current.next();
    }
  }, [flickityRef]);

  // Callback: slide prev
  const sliderPrev = useCallback(() => {
    if (flickityRef.current) {
      flickityRef.current.previous();
    }
  }, [flickityRef]);

  return (
    <Grid gutter={0}>
      <GridItem tabletFluid={1 / 2}>
        <ProductSwitcherStyled>
          <FlickityComponent
            getOptions={() => ({
              draggable: true,
              wrapAround: true,
              adaptiveHeight: false,
              pageDots: false,
              prevNextButtons: false,
              cellAlign: window.innerWidth < 737 ? 'center' : 'right',
              initialIndex,
            })}
            flickityRef={f => {
              flickityRef.current = f;
            }}
            disableImagesLoaded
          >
            {products.map(product => (
              <ProductSwitcherItemStyled key={product.id}>
                <ProductPreviewStyled
                  to={product.current === true ? null : product.fullUri}
                  shine={product.color}
                >
                  <ProductPreviewTitleStyled data-product-title>
                    {parseAsContent(product.title)}
                  </ProductPreviewTitleStyled>

                  {Boolean(product.bottle) && (
                    <ProductPreviewImageStyled
                      fadeIn={false}
                      fluid={product.bottle}
                      objectFit="contain"
                      objectPosition="50% 100%"
                      imgStyle={{ height: '100%' }}
                    />
                  )}
                </ProductPreviewStyled>
              </ProductSwitcherItemStyled>
            ))}
          </FlickityComponent>
          <SliderControlsStyled>
            {sliderPrev && (
              <PrevStyled onClick={sliderPrev}>
                <Icon type="chevronLeft" width="30" height="30" />
              </PrevStyled>
            )}
            {sliderIndicatorCurrent && sliderIndicatorAll && (
              <IndicatorStyled>
                {sliderIndicatorCurrent}/{sliderIndicatorAll}
              </IndicatorStyled>
            )}
            {sliderNext && (
              <NextStyled onClick={sliderNext}>
                <Icon type="chevronRight" width="30" height="30" />
              </NextStyled>
            )}
          </SliderControlsStyled>
        </ProductSwitcherStyled>
      </GridItem>
    </Grid>
  );
}

ProductSwitcher.propTypes = {
  navigateOnSelect: PropTypes.bool,
};

ProductSwitcher.defaultProps = {
  navigateOnSelect: true,
};
