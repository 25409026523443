/* eslint-disable react/destructuring-assignment */
import React from 'react';
import scrollToElement from 'scroll-to-element';

// import PropTypes from 'prop-types';
import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';

import ProductDetailSection from '../ProductDetailSection';
import { ProductDetailsStyled } from './ProductDetailsStyled';

export default function ProductDetails({
  sections,
  gradient,
  onScrollIntoView,
  isAbleToScroll,
  topKeys,
  sceneryImages,
  slug: product_slug,
}) {
  return sections.map(
    (
      {
        key,
        slug,
        title,
        description,
        sectionLink,
        sectionSecondaryLink,
        topline,
        badges,
        productBeerContainers,
        nutrientsTable,
      },
      index,
    ) => {
      const firstChild = index === 0;
      const lastChild = index === sections.length - 1;
      const isTopSection = topKeys.includes(key);
      let headlineElement = 'h2';

      // Primary button.
      let primaryButtonProps = null;

      if (isTopSection) {
        primaryButtonProps = {
          buttonPrimaryText: 'SCOPRI DI PIÙ',
          buttonPrimaryTheme: 'white',
          buttonPrimaryTarget: '_blank',
          buttonPrimaryOnClick: () => {
            onScrollIntoView(sections[1].key);
            setTimeout(() => {
              scrollToElement(`#${sections[1].slug}`);
            }, 10);
          },
        };
        headlineElement = 'h1';
      } else {
        primaryButtonProps = {
          buttonPrimaryText: sectionLink?.text,
          buttonPrimaryLink: sectionLink?.url,
          buttonPrimaryTarget: sectionLink?.target,
          buttonPrimaryTheme: 'white',
        };
      }

      // Secondary button.
      let secondaryButtonProps = null;

      if (isTopSection) {
        secondaryButtonProps = {
          buttonSecondaryText: sectionLink?.text,
          buttonSecondaryLink: sectionLink?.url,
          buttonSecondaryTarget: sectionLink?.target,
          buttonSecondaryTheme: 'white',
        };
      } else if (sectionSecondaryLink) {
        secondaryButtonProps = {
          buttonSecondaryText: sectionSecondaryLink?.text,
          buttonSecondaryLink: sectionSecondaryLink?.url,
          buttonSecondaryTarget: sectionSecondaryLink?.target,
          buttonSecondaryTheme: 'white',
        };
      }

      return firstChild || isAbleToScroll ? (
        <ProductDetailsStyled key={key} gradient={gradient}>
          <Grid right>
            <GridItem mobile={1} tabletFluid={isTopSection ? 3 / 7 : 3 / 5}>
              <ProductDetailSection
                key={`${product_slug}_${key}`}
                _key={key}
                slug={slug}
                title={title}
                topline={topline}
                badges={badges}
                description={description}
                onScrollIntoView={onScrollIntoView}
                nutrientsTable={nutrientsTable}
                productBeerContainers={productBeerContainers}
                isTopSection={isTopSection}
                lastChild={lastChild}
                firstChild={firstChild}
                sceneryImages={sceneryImages}
                headlineElement={headlineElement}
                textTeaserProps={{
                  ...primaryButtonProps,
                  ...secondaryButtonProps,
                }}
              />
            </GridItem>
          </Grid>
        </ProductDetailsStyled>
      ) : null;
    },
  );
}

ProductDetails.propTypes = {};
