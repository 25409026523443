import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

export const TitleStyled = styled('h3')({
  marginTop: 60,
  textTransform: 'uppercase',
  fontSize: 'inherit',
});

export const TableStyled = styled('table')({
  width: '100%',
});

export const TrStyled = styled('tr')({
  verticalAlign: 'top',
});

export const TdTitleStyled = styled('td')({
  paddingRight: 6,
  paddingTop: 6,
  paddingBottom: 6,

  [media(0, 'tabletFluid')]: {
    display: 'block',
    fontWeight: 'bold',
  },
});

export const TdDetailStyled = styled('td')({
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 6,
  textAlign: 'right',

  [media(0, 'tabletFluid')]: {
    display: 'block',
    textAlign: 'left',
    paddingLeft: 0,
  },
});

export const AdditionalTextStyled = styled('p')({
  paddingTop: 6,
  paddingBottom: 6,
  fontSize: '.8em',
  lineHeight: '1.7',

  [media('laptop')]: {
    marginLeft: '36.5%',
    textAlign: 'right',
    fontSize: '1em',
  },
});
